.component-text{
    text-align: center;

    h1{
        background-image: url(../images/decorative-line-1.svg);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 74px 20px;
        font-family: $temporal;
        font-size: 55px;
        line-height: 1.1;
        margin: 0 0 50px 0;
        padding: 0 0 30px 0;
    }

    h2{
        background-image: url(../images/decorative-line-2.svg);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 110px 3px;
        font-family: $im;
        font-size: 29px;
        line-height: 1.3;
        letter-spacing: 4px;
        margin: 0 0 30px 0;
        padding: 0 0 40px 0;
        text-transform: uppercase;

        &:not(:first-child){
            margin-top: 50px;
        }

    }

    h3{
        @include italic;
        font-size: 27px;
        line-height: 1.5555;
        margin: 0 0 30px 0;
    }


    h4{
        font-family: $dominican;
        font-size: 27px;
        text-transform: lowercase;
        margin: 0 0 40px 0;
    }



    p{
        font-size: 20px;
        line-height: 1.75;

        em{font-family: $caslon-italic}
        strong{font-weight: 800;}

        &:not(:last-child){
            margin: 0 0 30px 0;
        }

    }


}
