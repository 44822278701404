.card-menu-item{
    font-size: 20px;

    &:not(:last-child){
        margin: 0 0 30px 0;
    }

}

.card-menu-item__title{
    @include layout-flex;
    letter-spacing: 1px;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

.card-menu-item__content{
    @include italic;
}
