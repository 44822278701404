.component-heading-two{
    background-image: url(../images/decorative-line-2.svg);
    background-repeat: no-repeat;
    background-position: -8px 100%;
    background-size: 110px 3px;
    font-family: $dominican;
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 0  0 20px 0;
    text-transform: lowercase;
}
