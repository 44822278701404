.nf-form-cont{

    .nf-before-form-content{display: none}

    .nf-field-container{
        margin-bottom: 20px;
    }

    .ninja-forms-field{
        appearance: none;
        background: $white;
        border: none;
        border: 2px solid $black;
        color: $black;
        font-size: 17px;
        padding: 10px;
        text-transform: lowercase;

        &::placeholder{
            color: #ccc;
        }

    }

    .nf-error-required-error{
        @include italic;
    }


    .nf-error .ninja-forms-field{border-width: 2px}

}
