.component-newsletter{
    .component-button,
    .component-boxed,
    .component-boxed__inside{
        border-color: $white;
    }

    .component-button{
        &:hover{
            .component-boxed__inside{
                background: $white;
                color: $black;
            }
        }
    }

}

.component-newsletter__label{
    display: block;
    font-family: $dominican;
    letter-spacing: 1px;
    margin: 0 0 8px 0;
    text-transform: lowercase;
}

.component-newsletter__input{
    background: none;
    border: 2px solid $white;
    display: block;
    font-style: italic;
    font-size: 18px;
    margin: 0 0 8px 0;
    padding: 5px 10px;
    width: 100%;

    &::placeholder{
        color: $white;
    }

}
