.block-companies{
    margin: 0 0 45px 0;

    .boxes{
        @include layout-flex;

        .box{
            width:16%;

            @include breakpoint(to mobile-large){
                width: 100%;
            }

            &:not(:last-child){
                @include breakpoint(to mobile-large){
                    margin: 0 0 35px 0;
                }
            }

        }

        img{
            margin: 0 auto;
        }

    }

}

.block-companies__primary-logo{
    margin: 0 auto 40px auto;
}
