.component-gallery-slideshow{
    @include layout-flex($justify: center);
    background: rgba($black,.85);
    opacity: 0;
    transition: all .5s ease;
    visibility: hidden;

    @include breakpoint(to mobile-large){
        display: none;
    }

    &.state-visible{
        opacity: 1;
        visibility: visible;
    }

    .boxed{
        background: $white;
        max-width: 800px;
        padding: 15px;
    }

    .slide{
        transition: opacity 1s ease;
    }

    .slide:not(.slick-center){
        @include breakpoint(from mobile-large){
            opacity: .25;
        }
    }

    img{
        margin: 0 auto;
    }

    figcaption{
        @include italic;
        font-size: 18px;
        margin: 10px 0 0 0;
    }

}

.component-gallery-slideshow__arrow{
    svg{
        fill: $black;
        height: 15px;
        width: 18px;
    }
}


.component-gallery-slideshow__close{
    svg{
        $d: 15px;
        fill: $black;
        height: $d;
        width: $d;
    }
}


.component-gallery-slideshow__navigation{
    @include layout-flex($justify: flex-end);
    padding: 10px 0 0 0;

    a{
        margin: 0 0 0 10px;
    }

}
