.block-menu{

}

.block-menu__header{
    margin: 0 0 40px 0;
    text-align: center;
}

.block-menu__title{
    font-size: 29px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}

.block-menu__content{
    font-size: 20px;
}
