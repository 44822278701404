.component-social{
    @include layout-flex($justify: flex-start);

    a{
        &:not(:last-child){
            margin-right: 15px;
        }
    }

    svg{
        $d: 22px;
        fill: $white;
        height: $d;
        width: $d;
    }

    &.is-alt{
        svg{
            fill: $black;
        }
    }

}
