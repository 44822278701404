.component-slideshow{
    @include levels(1);

    .slick-dots{
        @include layout-flex($justify: center);
        bottom: 65px;
        display: flex !important;
        left: 0;
        list-style: none;
        position: absolute;
        width: 100%;

        li{
            margin: 0 10px;

            &:hover,&.slick-active{
                button{background: $white;}
            }

        }

        button{
            $d: 14px;
            appearance: none;
            background: none;
            border: 2px solid $white;
            border-radius: 100%;
            display: block;
            height: $d;
            overflow: hidden;
            text-indent: -9999px;
            padding: 0;
            width: $d;
        }

    }


}
