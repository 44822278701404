.component-boxed{
    border: 2px solid $black;
    padding: 2px;
}

.component-boxed__inside{
    border: 1px solid $black;
    display: block;
    padding: 8px;
}
