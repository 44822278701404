.component-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(1.1);

    @include breakpoint(to mobile-large){
        transform: none !important;
    }

    img{opacity: 0}

}
