.card-hour{
    &:not(:last-child){
        margin-bottom: 35px;
    }
}

.card-hour__title{
    border-bottom: 2px solid $black;
    font-family: $dominican;
    font-size: 28px;
    letter-spacing: 1px;
    margin: 0 0 13px 0;
    padding: 0 0 15px 0;
    text-transform: lowercase;
}

.card-hour__content{
    font-size: 18px;
    line-height: 1.666666;
}
