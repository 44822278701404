.component-reservations{
    @include levels(1);
    background: $white;
    margin-top: -100px;
    max-width: 700px;
    padding: 10px;

    .boxes{
        @include layout-flex($align: stretch);

        @include breakpoint(to mobile-large){
            flex-direction: column;
        }

        > *{
            width: 28%;

            @include breakpoint(to mobile-large){
                width: 100%;
            }

            &:last-child{
                width: 35%;

                @include breakpoint(to mobile-large){
                    width: 100%;
                }
            }

            &:not(:last-child){
                @include breakpoint(to mobile-large){
                    margin: 0 0 15px 0;
                }
            }

        }

    }


    .component-form__input{
        @include breakpoint(to mobile-large){
            min-height: 30px;
        }
    }

}

.component-reservations__form{
    padding: 20px 30px;
}
