.block-contact{

    .boxes{
        @include layout-flex($align: flex-start);
    }

    .box{
        width: 48%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

    .box-1{
        @include breakpoint(to mobile-large){
            margin: 0 0 40px 0;
        }
    }

}

.block-contact__heading{
    border-bottom: 2px solid $black;
    font-family: $dominican;
    font-size: 28px;
    letter-spacing: 1px;
    margin: 0 0 18px 0;
    padding: 0 0 15px 0;
    text-transform: lowercase;

    &.is-alt{
        border: none;
        margin: -11px 14px 0 0;
        padding: 0;
    }

}

.block-contact__content{
    &:not(:last-child){
        margin-bottom: 30px;
    }

    p{
        font-size: 18px;
        line-height: 1.6;

        &:not(:last-child){
            margin-bottom: 30px;
        }
    }

    address{
        font-size: 18px;
        line-height: 1.4;
    }

}
