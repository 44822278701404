.card-list{
    em{
        @include italic;
        text-transform: uppercase;
    }
}

.card-list__content{
    font-size: 18px;
    line-height: 1.66666;
}
