.component-form{

}


.component-form__input{
    appearance: none;
    background: $white;
    border: none;
    border: 2px solid $black;
    border-radius: 0;
    color: $black;
    font-family: $dominican;
    font-size: 17px;
    padding: 0 10px;
    text-transform: lowercase;

    &::placeholder{
        color: $black;
    }

    &.is-calendar{
        background-image:url(../images/icon-calendar.svg);
        background-repeat: no-repeat;
        background-position: 97% 50%;
        background-size: 20px;
    }

}
