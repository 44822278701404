.component-intro{
  margin: 100px 0 50px 0;
  position: relative;
  z-index: 25;
}

.component-intro__celebration{
  left: 50%;
  bottom: -65px;
  margin-left: 250px;
  position: absolute;
  right: 0;

  @include breakpoint(to tablet-small) {
    display: none;
  }

  svg{
    display: block;
    height: 152px;
    width: 156px;
  }
}

.component-intro__logo{
    margin: 0 auto;

    @include breakpoint(to mobile-large){
        max-width: 80%;
    }

}
