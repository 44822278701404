.card-room{
    @include layout-flex;

    .box{
        width: 50%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }
    }

}

.card-room__boxed{
    padding: 75px;
}

.card-room__title{

}

.card-room__content{
    font-size: 18px;
    line-height: 1.6666;
    margin: 0 0 15px 0;
}

.card-room__price{
    @include italic;
    font-size: 23px;
    margin: 0 0 20px 0;
}
