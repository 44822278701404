.block-navigation{
    font-family: $dominican;
    letter-spacing: 1px;
    text-transform: lowercase;

    > ul{
        @include layout-flex;
        list-style: none;

        @include breakpoint(to desktop-small){
            justify-content: center;
        }

        > li{
            @include levels(1);

            > a{@include levels(1);}

            &:hover {
                > ul{
                    transform: translateY(0);
                    transition: all .4s .3s, transform .6s 0s ease;
                    opacity: 1;
                    visibility: visible;

                    > li{
                        opacity: 1;

                        $delay: .1s;

                        @for $i from 1 through 12 {
                            &:nth-child(#{$i}){
                                transition-delay: ($i * $delay) + .25;
                            }
                        }

                    }
                }
            }

            > ul{
                @include levels(0);
                list-style: none;
                padding: 15px;

                @include breakpoint(from mobile-large){
                    background: $white;
                    background-image: url('../images/pelicaninn_seamlessbackgroundwhite.jpg');
                    left: -15px;
                    opacity: 0;
                    position: absolute;
                    transition: all .4s 0s, transform .6s .3s ease;
                    list-style: none;
                    transform: translateY(-100%);
                    top: 100%;
                    visibility: visible;
                    width: 200px;
                }

                @include breakpoint(to mobile-large){
                    display: none;
                }


                > li{
                    list-style: none;
                    line-height: 1.2;
                    transition: all .4s ease;

                    @include breakpoint(from mobile-large){
                        opacity: 0;
                    }

                    &:not(:last-child){
                        margin: 0 0 13px 0;
                    }

                }

            }

        }

    }


    a{
        @include levels(0);

        &:after{
            background: $black;
            bottom: -4px;
            content: ' ';
            height: 3px;
            left: 0;
            transition: all .4s ease;
            position: absolute;
            width: 0%;
        }

        &:hover{
            &:after{
                width: 100%;
            }
        }

    }

}
