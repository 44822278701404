.view-footer{
    background: url(../images/seamless_wall_texture2black.jpg);
    background-size: contain;
    color: $white;
    padding: 50px 0;

    @include breakpoint(to mobile-large){
        padding: 50px 10px;
    }

}
