a{
    color: inherit;
    text-decoration: none;
}

body.site{
    background-image: url('../images/pelicaninn_seamlessbackgroundwhite.jpg');
    background-size: contain;
    font-family: $caslon;
}

p{
    hyphens: none;
}

:focus{outline: none}

h1,h2,h3,h4,h5,figure{font-size:1em;margin: 0}

.container{
    margin: 0 auto;
    max-width: 960px;
    width: 90%;
}

strong{
    font-weight: 800;
}

u{
    text-decoration: underline;
}


mark{
    background: none;
    color: inherit;
}
