.component-button{
    background: none;
    border: none;
    display: inline-block;
    font-family: $dominican;
    letter-spacing: 1px;
    text-transform: lowercase;

    .component-boxed__inside{
        padding: 2px 14px 5px 14px;
        transition: all .4s ease;
    }

    &:hover{
        .component-boxed__inside{
            background-color: $black;
            color: $white;
        }
    }

}

.component-button-menu{
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 650;

    @include breakpoint(from mobile-large){
        display: none;
    }

    .line{
        @include levels(0);
        margin: 6px 0;
        transition: all .4s .4s ease;
        transform-origin: 50% 50%;
        top: 0;

        svg{
            display: block;
            height: 5px;
            transition: transform .4s 0s ease;
            width: 30px;
        }
    }

}
