.block-footer{
    margin: 0 0 30px 0;

    .boxes{
        @include layout-flex($align: flex-start);
    }

    .box{
        width: 48%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

    .box-1{
        @include breakpoint(to mobile-large){
            margin: 0 0 25px 0;
        }
    }

}

.block-footer__line-1{
    margin: 10px 0;

    svg{
        fill: $white;
        height: 3px;
        width: 75px;
    }
}

.block-footer__address,
.block-footer__phone{
    font-family: $dominican;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.16;
    text-transform: lowercase;
}

.block-footer__phone{
    margin-bottom: 20px;
}
