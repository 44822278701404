.card-instagram{
    &:hover{
        .card-instagram__text{
            opacity: 1;
        }
    }
}

.card-instagram__text{
    @include layout-flex($justify: center);
    @include italic;
    background: rgba($black,.8);
    color: $white;
    font-size: 20px;
    line-height: 1.4;
    opacity: 0;
    padding: 50px;
    transition: all .4s ease;

    @include breakpoint(to mobile-large){
        background: none;
        color: $black;
        opacity: 1;
        position: static;
    }


}

.card-instagram__image{
  background-position: center;
  background-size: cover;
}
.card-instagram__placeholder{
  opacity: 0;
}
