.block-header{
    @include levels(5);
    background-image: url('../images/pelicaninn_seamlessbackgroundwhite.jpg');
    background-size: contain;
    left: 0;
    padding: 13px 0;
    position: fixed;
    top: 0;
    width: 100%;

    .boxes{
        @include layout-flex;
    }

    .box-1{margin-right: 35px}

    .box-2{
        flex: 1;
    }
    .box-3{margin-left: 35px}

}

.block-header__logo{
    max-width: 60px;
}
