.state-menu-open{
    .block-menu-mobile{
        opacity: 1;
        visibility: visible;
    }

    .component-button-menu{

        .line{
            transition-delay: 0s;

            svg{
                transition-delay: .4s;
            }

        }

        $o: 11px;

        .line-1{
            top: $o;
            svg{
                transform: rotate(45deg);
            }
        }

        .line-2{opacity: 0}

        .line-3{
            top: -$o;
            svg{
                transform: rotate(-45deg);
            }
        }
    }

}
