.component-masonry{
    .gutter-sizer{
        @include breakpoint(from mobile-large){
            width: 2%;
        }
    }

    .grid-sizer,
    .grid-item{
        width: 49%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }
}
