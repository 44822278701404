.block-menu-mobile{
    @include layout-flex($direction: column,$justify: center);
    background: rgba($white,.9);
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    visibility: hidden;
    top: 0;
    transition: all .4s ease;
    width: 100vw;
    z-index: 600;

    img{margin: 0 auto}

    .box-2{
        text-align: center;
        margin: 50px 0;
    }

    .block-navigation{
        margin: 0 0 8px 0;

        > ul{
            flex-direction: column;

            li{
                &:not(:last-child){
                    margin: 0 0 7px 0;
                }
            }

        }

    }

    .block-navigation__submenu{
        color: #444;
    }

}

.block-menu-mobile__location{
    @include italic;
    font-size: 20px;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}
