.card-press{
    border-width: 4px;
    font-size: 20px;
    margin-bottom: 2%;
    padding: 5px;
    text-align: center;

    @include breakpoint(to mobile-large){
        margin: 0 0 25px 0;
    }

}

.card-press__boxed{
    border-width: 2px;
    padding: 25px;
}

.card-press__header{
    background-image: url(../images/decorative-line-1.svg);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 74px 20px;
    margin: 0 0 30px 0;
    padding: 0 0 45px 0;
}

.card-press__title{
    font-family: $im;
    font-size: 29px;
    letter-spacing: 3px;
    line-height: 1.3;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}

.card-press__subtitle{
    @include italic;
}

.card-press__pubdate{
    display: block;
    margin: 15px 0 0 0;
}

.card-press__content{
    line-height: 1.4;

    p{
        &:not(:last-child){
            margin: 0 0 30px 0;
        }
    }

    em{
        @include italic;
    }
}
