.block-menus{

    #menusContainer{
        max-width: 100%;
    }

    #sp_main .sp_tabs{
        overflow: visible !important;
    }

    #sp_main .sp_menu,
    #sp_main #tabsouter.borderTop,
    #sp_main #tabsouter,
    #sp_main #tabsouterouter{
        border: none !important;
    }

    #sp_main{

        .sp_st.fn{

            .sp_st_section_title{
                font-family: $temporal;
                font-size: 40px;
                font-weight: 400;
                text-transform: none;
            }

            background: none;
            border-bottom: 3px solid $black;
        }

        .spheaderContainer .sp_sd{
            @include italic;
            color: $black !important;
            text-align: center;
        }

        .hproduct{
            margin-left: 0;
            margin-right: 0;
            width: 45%;
        }

    }

    .sp_menu.hstorefrontcategory{
        > .sp_md{
            font-family: $caslon;
            font-size: 17px;
            text-align: center;
        }
    }

    #sp_tabs{display: none !important;}

    #sp_panes .sp_mf.sp_pb{border:none;background-color: transparent !important;}

    #sp_foot{
        display: none;
    }

    #sp_main .sp_menu{
        color: $black;
    }

    #sp_main .sp_option span.addon_title,
    #sp_main .detailsDiv .item_addon_container,
    #sp_main .span_leaders,
    #sp_main .sp_priceUnit,
    #sp_main .hstorefrontproduct .fn,
    #sp_main .sp_option ul.sp_priceLine:before,
    #sp_main .sp_st.fn{
        border-color: $black !important;
        color: $black !important;
    }

    #sp_main .sp_menu.hstorefrontcategory{
        background-color: transparent;
    }

    #sp_main #sp_menu_drop{
        color: $black;
        border: none !important;
        margin: 0 0 50px 0;
        visibility: visible !important;
        position: static !important;

        ul{
            @include layout-flex($justify: center);
            font-family: $dominican;
            font-size: 17px;
            letter-spacing: 1px;
            text-transform: lowercase;
        }

        li{
            $d: 35px;
            border-bottom: 2px solid transparent;
            margin: 0 $d 35px $d;
            padding: 0 0 5px 0 !important;
            text-align: center;

            @include breakpoint(to mobile-large){
                margin: 0 0 10px 0;
                width: 100%;
            }

            &:hover, &.is-active{
                border-bottom-color: $black;
            }

        }

    }

    #sp_main .hstorefrontproduct{

        .sp_option,
        p.fn{
            font-family: $caslon;
            font-size: 17px;
            text-transform: uppercase;
        }

        p.sp_description{
            @include italic;
            font-size: 17px;
        }

    }


    @include breakpoint(to mobile-large){

        #sp_main .sp_menu.hstorefrontcategory .sp_st.fn .sp_show{
            display: none !important;
        }

        #sp_main .sp_st.fn,
        #sp_main .spheaderContainer{
            border: none !important;
        }

        #sp_main #sp_panes .sp_st{
            padding-bottom: 0;
        }

        .sp_st_section_title{
            line-height: 1.4 !important;
        }

    }

    #sp_main #sp_menu_drop ul li.sp_menu_drop_down{text-align: center !important}


}
