// colors
$white: #fff;
$black: #231f20;

// fonts
$dominican: 'dominican_small_capsregular';
$temporal: 'temporalregular';
$caslon: 'ITC Founder\'s Caslon W00';
$caslon-italic: 'ITC Founders Caslon W01';
$im: 'IM Fell English', serif;
