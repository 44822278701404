.block-hours{

    .boxes{
        @include layout-flex($align: flex-start);
    }

    .box{
        width: 48%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

    .box-1{
        @include breakpoint(to mobile-large){
            margin: 0 0 35px 0;
        }
    }

}
