.card-event{
    border-width: 4px;
    padding: 5px;
    margin-bottom: 2%;
    text-align: center;

    @include breakpoint(to mobile-large){
        margin: 0 0 25px 0;
    }

    .component-boxed__inside{
        border-width: 2px;
        padding: 30px 40px;
    }

}

.card-event__title{
    background-image: url(../images/decorative-line-1.svg);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 58px 15px;
    font-family: $im;
    font-size: 29px;
    letter-spacing: 3px;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
}

.card-event__content{
    font-size: 20px;
    line-height: 1.4;

    p{
        &:not(:last-child){
            margin: 0 0 30px 0;
        }
    }

    em{
        @include italic;
    }

}

.card-event__action{
    font-size: 20px;
    font-style: italic;

    a{
        border-bottom: 1px solid $black;
    }

}
