.block-rooms{
    .row:nth-child(even){
        .box-1{
            @include breakpoint(from mobile-large){
                order:1;
            }
        }

        .box-2{
            @include breakpoint(from mobile-large){
                text-align: right;
            }
        }

        .component-heading-two{
            @include breakpoint(from mobile-large){
                    background-position: 102% 100%;
            }
        }
    }
}
