.view-page{

    > *:not(:last-child):not(.block-rooms){
        margin-bottom: 55px;
    }

    >.block-text:last-child{
        margin-bottom: 55px;
    }

}
