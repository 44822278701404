.block-gallery{
    overflow-x: hidden;

    .boxed{@include levels(0);}

    .boxes{
        @include layout-flex;
        background: url(../images/seamless_wall_texture2black.jpg);
        background-size: contain;
    }

    .box{
        width: 25%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

}
