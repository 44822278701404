.card-menu{
    &:not(:last-child){
        margin: 0 0 50px 0;
    }

    .boxes{
        @include layout-flex($align: flex-start);
    }

    .box{
        width: 48%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

    .box-1{
        @include breakpoint(to mobile-large){
            margin: 0 0 30px 0;
        }
    }

}

.card-menu__title{
    border-bottom: 2px solid $black;
    font-family: $temporal;
    font-size: 40px;
    margin: 0 0 35px 0;
    padding: 0 0 20px 0;
    text-align: center;
}
