.block-instagram{

    .boxes{
        @include layout-flex;
    }

    .box{
        @include levels(1);
        width: 33.333333%;

        @include breakpoint(to mobile-large){
            width: 100%;
        }

    }

}
