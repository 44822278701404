.component-celebration{
  background-image: url(../images/decorative-line-2.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 110px 3px;
  margin-bottom: 35px;
  padding-bottom: 45px;

  @include breakpoint(from tablet-small) {
    display: none;
  }

  svg{
    display: block;
    height: 200px;
    margin: 0 auto;
    width: 205px;
  }
}
